/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 64.3.0-v202405011708
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqLogsApiClass {
 
   constructor() {}

  /**
   * @summary Download the log files in zipped form.
   * @param {string} [downloadLogsCookie] - The value to return as a cookie.
   */
  public downloadLogs(
    {
      downloadLogsCookie
    } : {
      downloadLogsCookie?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/logs/download`,

      params: omitBy({
        ['downloadLogsCookie']: downloadLogsCookie
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Get a list of available log files.
   */
  public getAvailableLogFiles(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/logs`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<Array<string>>;
  }

  /**
   * @summary Get a collection of log messages
   * @param {string} log - ID of the log to retrieve
   * @param {string} startTime - Starting time to filter log messages on.
   * @param {number} limit - The (soft) limit for the number of log messages to return.
   * @param {string} [endTime] - Ending time to filter log messages on. It will default to the current time if none is specified.
   * @param {string} [sorted=ASC] - Whether log messages should be sorted in ascending order, &#x27;ASC&#x27;, by their time or descending order, &#x27;DESC&#x27;.
   * @param {string} [level=DEBUG] - The minimum log level to include.
   * @param {string} [sourceContains] - A substring that the source field of the log messages must contain to be returned.
   * @param {string} [messageContains] - A substring that the message field of the log messages must contain to be returned.
   */
  public getLogs(
    {
      log,
      startTime,
      limit,
      endTime,
      sorted,
      level,
      sourceContains,
      messageContains
    } : {
      log: string,
      startTime: string,
      limit: number,
      endTime?: string,
      sorted?: string,
      level?: string,
      sourceContains?: string,
      messageContains?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(log)) {
      throw new Error("'log' parameter required");
    }

    if (isNil(startTime)) {
      throw new Error("'startTime' parameter required");
    }

    if (isNil(limit)) {
      throw new Error("'limit' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/logs/${encodeURIComponent(String(log))}`,

      params: omitBy({
        ['startTime']: startTime,
        ['endTime']: endTime,
        ['limit']: limit,
        ['sorted']: sorted,
        ['level']: level,
        ['sourceContains']: sourceContains,
        ['messageContains']: messageContains
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<Array<models.LogMessage>>;
  }

  /**
   * @summary Send a support request along with the log files to Seeq.
   */
  public send(
    body: models.SupportRequestInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/logs/send`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.SupportRequestOutputV1>;
  }

}


export const sqLogsApi = new sqLogsApiClass();
